import { window } from 'browser-monads';
import { gsap } from 'gsap'
const resolveConfig = require("tailwindcss/resolveConfig");
const tailwindConfig = require("../../tailwind.config.js");
const fullConfig = resolveConfig(tailwindConfig);

export default {
  fadeNav(toWhite) {
    const DURATION = 0.5
    const circleLinks = document.querySelectorAll('#nav .circle-link')

    if (toWhite) {
      gsap.to('#nav a', {
        color: fullConfig.theme.colors.sand,
        duration: DURATION,
        ease: "power2.out",
      })
      gsap.to('#nav svg path', {
        fill: fullConfig.theme.colors.sand,
        duration: DURATION,
        ease: "power2.out",
      })
      document.querySelector('#nav .menu-open').classList.add('menu-open--inverse')
      circleLinks.forEach(link => link.classList.add('circle-link--inverse'))
    } else {
      circleLinks.forEach(link => link.classList.remove('circle-link--inverse'))
      document.querySelector('#nav .menu-open').classList.remove('menu-open--inverse')
      gsap.to('#nav a', {
        color: fullConfig.theme.colors.dark,
        duration: DURATION,
        ease: "power2.out",
      })
      gsap.to('#nav svg path', {
        fill: fullConfig.theme.colors.dark,
        duration: DURATION,
        ease: "power2.out",
      })
      gsap.to('#nav .circle-link__circle:before', {
        borderColor: fullConfig.theme.colors.dark,
        ease: "power2.out",
      })
    }
  },
  fadeOutFromNav() {
    gsap.to('#scroll-container', {
      opacity: 0,
      duration: 0,
      onComplete: () => {
        gsap.to(['body', '#nav', '.nav', '.dark-bg-mask'], {
          opacity: 0,
          duration: 0.3,
          ease: "power2.out"
        })
      }
    })
  },
  fadeOutFromPurple() {
    gsap.to('body', {
      opacity: 0,
      duration: 0.3,
      ease: "power2.out"
    })
  },
  menuToContact() {
    document.body.style.backgroundColor = fullConfig.theme.colors.dark
    gsap.to(['#nav', '.nav'], {
      opacity: 0,
      duration: 0.3,
      ease: "power2.out"
    })
  },
  isDesktop() {
    return window.screen.width >= 1024
  },
  checkIfScrollTop(linkPath) {
    const windowPath = window.location.pathname.replace("/", "")
    if (linkPath == windowPath) {
      window.locomotiveScroll.scrollTo(0)
    }
  },
  checkIfCurrentPath(linkPath) {
    const windowPath = window.location.pathname.replace("/", "")
    return windowPath == linkPath
  },
  checkScrollDirectionIsUp(event) {
    if (event.wheelDelta) {
      return event.wheelDelta > 0;
    }
    return event.deltaY < 0;
  },
  getTransitionEntryDelay() {
    return this.isDesktop() ? 0.5 : 0.5
  },
  getTransitionExitDelay() {
    return this.isDesktop() ? 0.5 : 0.5
  },
  getScrollToDuration() {
    return this.isDesktop() ? 2.5 : 0.5
  },
  resetPage(darkBG) {
    if (!darkBG) {
      document.querySelector('body').classList.remove('cursor-inverse')
    }
    document.querySelector('body').classList.remove('is-bottom')
    document.body.classList.add('loaded-preloader')
  },
  getSandHex() {
    return fullConfig.theme.colors.sand
  },
  getPurpleHex() {
    return fullConfig.theme.colors.purple
  }
}