import React, { Component } from "react";
import "../styles/scroll.css";
import CircleLink from "./circle-link";
import Cursor from "./cursor";
import Nav from "./nav";
import TransitionLink from 'gatsby-plugin-transition-link'
import { gsap } from 'gsap'
import LocomotiveScroll from "locomotive-scroll"
import { ScrollTrigger } from "gsap/ScrollTrigger"
import { scroll } from "../theme"
import utils from './utils'
import { window } from "browser-monads";


export default class Layout extends Component {
  constructor(props) {
    super(props)

    this.state = {
      lastScrollTop: 0
    }
  }

  componentDidMount() {
    gsap.registerPlugin(ScrollTrigger)
    gsap.core.globals('ScrollTrigger', ScrollTrigger)

    // // Set up nav on mobile
    // if (!utils.isDesktop()) {
    //   window.addEventListener("scroll", () => {
    //     const nav = document.querySelector('#nav')
    //     var st = window.pageYOffset || document.documentElement.scrollTop;
    //     if (st > this.state.lastScrollTop) {
    //       nav.classList.add('nav-hidden')
    //     } else {
    //       nav.classList.remove('nav-hidden')
    //     }
    //     this.setState({
    //       lastScrollTop: st <= 0 ? 0 : st
    //     })
    //   }, false);
    // }

    if (window.locomotiveScroll) {
      window.locomotiveScroll.destroy()
    }

    let locomotiveScroll
    locomotiveScroll = new LocomotiveScroll({
      el: document.querySelector(scroll.container),
      ...scroll.options,
    })
    locomotiveScroll.update()

    // Exposing to the global scope for ease of use.
    window.locomotiveScroll = locomotiveScroll

    locomotiveScroll.on("scroll", func => {
      try {
        // ScrollTrigger.update();
      } catch {
      }
      const nav = document.querySelector('#nav')
      if (utils.isDesktop()) {
        if (func.direction == "down") {
          if (!nav.classList.contains('nav-hidden') && !document.body.classList.contains('is-bottom')) {
            nav.classList.add('nav-hidden')
          }
        } else { // Going up
          if (nav.classList.contains('nav-hidden')) {
            nav.classList.remove('nav-hidden')
          }
        }
      } else { // Mobile
        if (func.direction == "down" && func.scroll.y > 100) {
          nav.classList.add('nav-hidden')
        } else {
          nav.classList.remove('nav-hidden')
        }
      }

      // Update `data-direction` with scroll direction.
      // document.documentElement.setAttribute("data-direction", func.direction)
      // document.documentElement.setAttribute("data-y", func.scroll.y)
    })

    // tell ScrollTrigger to use these proxy methods for the ".smooth-scroll" element since Locomotive Scroll is hijacking things
    ScrollTrigger.scrollerProxy(scroll.container, {
      scrollTop(value) {
        return arguments.length ? locomotiveScroll.scrollTo(value, 0, 0) : locomotiveScroll.scroll.instance.scroll.y;
      }, // we don't have to define a scrollLeft because we're only scrolling vertically.
      getBoundingClientRect() {
        return { top: 0, left: 0, width: window.innerWidth, height: window.innerHeight };
      },
      // LocomotiveScroll handles things completely differently on mobile devices - it doesn't even transform the container at all! So to get the correct behavior and avoid jitters, we should pin things with position: fixed on mobile. We sense it by checking to see if there's a transform applied to the container (the LocomotiveScroll-controlled element).
      pinType: document.querySelector(scroll.container).style.transform ? "transform" : "fixed"
    });

    // each time the window updates, we should refresh ScrollTrigger and then update LocomotiveScroll. 
    ScrollTrigger.addEventListener("refresh", () => {
      try {
        locomotiveScroll.update()
      } catch {
      }
    });

    window.scrollTrigger = ScrollTrigger

    // after everything is set up, refresh() ScrollTrigger and update LocomotiveScroll because padding may have been added for pinning, etc.
    ScrollTrigger.refresh();

    document.querySelector('body').classList.remove('cursor-inverse')
    document.querySelector('body').classList.remove('nav-open')


  }

  render() {
    const { children, hideFooter } = this.props
    return (
      <div>
        <Cursor />
        <Nav />
        <div className="dark-bg-mask bg-dark min-h-screen transform -translate-y-full fixed lg:absolute top-0 w-full z-10"></div>
        {/* Outside Scroll */}
        <div id="scroll-container">
          {children}
          {!hideFooter && <footer id="footer" className="min-h-screen relative flex items-end align-stretch justify-center">
            <div className="absolute top-1/2 transform -translate-y-1/2 w-full text-center">
              <ul className="mb-8">
                <li>
                  <a data-text={utils.isDesktop() ? "PHONE" : "P"} className="link-generic font-sc text-lg pretext" href="tel:0733332858"> (07) 3333 2858</a>
                </li>
                <li>
                  <a data-text={utils.isDesktop() ? "EMAIL" : "E"} className="link-generic pretext font-sc text-lg" href="mailto:admin@lewisandtrovas.com"> admin@lewisandtrovas.com</a>
                </li>
                <li>
                  <a data-text={utils.isDesktop() ? "ADDRESS" : "A"} target="_blank" rel="noopener noreferrer" className="link-generic pretext font-sc text-lg" href="https://goo.gl/maps/jG8ngUgKb1PN6PfV6"> Suite 702, Level 7, 10 Market St<br /> Brisbane CBD, 4000</a>
                </li>
              </ul>
              <CircleLink duration={1} title="Book Consultation" to="/contact" isLink={true} onPress={() => {
                // utils.checkIfScrollTop("contact")
                gsap.to('.dark-bg-mask', {
                  translateY: 0,
                  ease: "power3.out",
                  duration: 1,
                  onComplete: () => {
                    utils.fadeNav(true)
                  }
                })
              }} />
            </div>
            <div className="w-full flex justify-center pb-12">
              <ul className="flex flex-wrap lg:flex-nowrap justify-center">
                <li className="mx-4">
                  <TransitionLink
                    onClick={() => {
                      utils.checkIfScrollTop("")
                    }}
                    className={`link-generic font-mabry tracking-wider uppercase text-sm ${utils.checkIfCurrentPath("") ? "opacity-50" : ""}`}
                    to="/"
                    exit={{
                      trigger: ({ exit, node }) => {
                        utils.fadeOutFromPurple()
                      },
                      length: utils.getTransitionExitDelay()
                    }}
                    entry={{
                      delay: utils.getTransitionEntryDelay()
                    }}
                  >
                    Home
                  </TransitionLink>
                </li>
                <li className="mx-4">
                  <TransitionLink
                    onClick={() => {
                      utils.checkIfScrollTop("services")
                    }}
                    className={`link-generic font-mabry tracking-wider uppercase text-sm ${utils.checkIfCurrentPath("services") ? "opacity-50" : ""}`}
                    to="/services"
                    exit={{
                      trigger: ({ exit, node }) => {
                        utils.fadeOutFromPurple()
                      },
                      length: utils.getTransitionExitDelay()
                    }}
                    entry={{
                      delay: utils.getTransitionEntryDelay()
                    }}
                  >
                    Services
                  </TransitionLink>
                </li>
                <li className="mx-4">
                  <TransitionLink
                    onClick={() => {
                      utils.checkIfScrollTop("referrals")
                    }}
                    className={`link-generic font-mabry tracking-wider uppercase text-sm ${utils.checkIfCurrentPath("referrals") ? "opacity-50" : ""}`}
                    to="/referrals"
                    exit={{
                      trigger: ({ exit, node }) => {
                        utils.fadeOutFromPurple()
                      },
                      length: utils.getTransitionExitDelay()
                    }}
                    entry={{
                      delay: utils.getTransitionEntryDelay()
                    }}
                  >
                    Referrals
                  </TransitionLink>
                </li>
                <li className="mx-4">
                  <TransitionLink
                    onClick={() => {
                      utils.checkIfScrollTop("about")
                    }}
                    className={`link-generic font-mabry tracking-wider uppercase text-sm ${utils.checkIfCurrentPath("about") ? "opacity-50" : ""}`}
                    to="/about"
                    exit={{
                      trigger: ({ exit, node }) => {
                        utils.fadeOutFromPurple()
                      },
                      length: utils.getTransitionExitDelay()
                    }}
                    entry={{
                      delay: utils.getTransitionEntryDelay()
                    }}
                  >
                    About
                  </TransitionLink>
                </li>
                <li className="mx-4">
                  <TransitionLink
                    onClick={() => {
                      utils.checkIfScrollTop("articles")
                    }}
                    className={`link-generic font-mabry tracking-wider uppercase text-sm ${utils.checkIfCurrentPath("articles") ? "opacity-50" : ""}`}
                    to="/articles"
                    exit={{
                      trigger: ({ exit, node }) => {
                        utils.fadeOutFromPurple()
                      },
                      length: utils.getTransitionExitDelay()
                    }}
                    entry={{
                      delay: utils.getTransitionEntryDelay()
                    }}
                  >
                    Articles
                  </TransitionLink>
                </li>
                <li className="mx-4">
                  <TransitionLink
                    onClick={() => {
                      utils.checkIfScrollTop("contact")
                    }}
                    className={`link-generic font-mabry tracking-wider uppercase text-sm ${utils.checkIfCurrentPath("contact") ? "opacity-50" : ""}`}
                    to="/contact"
                    exit={{
                      trigger: ({ exit, node }) => {
                        gsap.to('.dark-bg-mask', {
                          translateY: 0,
                          ease: "power3.out",
                          duration: 1,
                          onComplete: () => {
                            utils.fadeNav(true)
                          }
                        })
                      },
                      length: 1
                    }}
                    entry={{
                      delay: 1
                    }}
                  >
                    Contact
                  </TransitionLink>
                </li>
                <li className="mx-4">
                  <a target="_blank" rel="noopener noreferrer" className="link-generic font-mabry tracking-wider uppercase text-sm" href="https://www.linkedin.com/company/lewis-and-trovas-family-lawyers">LinkedIn</a>
                </li>
              </ul>
            </div>
          </footer>
          }
        </div>
      </div>
    )
  }
}