// See https://tailwindcss.com/docs/configuration for details
module.exports = {
  purge: ["./src/**/*.js"],
  // https://github.com/tailwindlabs/tailwindcss-forms
  plugins: [],
  theme: {
    fontFamily: {
      'sailec': ['Sailec', 'sans'],
      'mabry': ['Mabry', 'sans'],
      'sc': ['Sainte Colombe', 'sans'],
      // 'display-italic': ['XheighterBoldOblique', 'sans'],
      // 'body': ['NBAkademieMono', 'sans']
    },
    fontSize: {
      sm: ['12px', '1'],
      base: ['16px', '1.36'],
      md: ['18px', '24.48px'],
      lg: ['23px', '31px'],
      xl: ['34px', '130%'],
      xxl: ['64px', '130%'],
    },
    colors: {
      'dark': '#353136',
      'purple': '#A38F9B',
      'sand': '#EDE4DF',
      transparent: 'transparent'
    },
    extend: {
      lineHeight: {
        'tighter': '0.9',
      },
      margin: {
        '192': '48rem'
      }
    }
  }
};
