import React from 'react'
import TransitionLink from 'gatsby-plugin-transition-link'
import utils from './utils'

export default function CircleLink({ title, inverse, onPress, to, isLink, duration, side, cssClass, isSubmit }) {

  return (
    <div className={cssClass}>
      {
        isLink ? <TransitionLink
          to={to}
          className={`circle-link ${inverse ? 'circle-link--inverse' : ''} font-mabry tracking-wider uppercase text-sm`}
          exit={{
            trigger: ({ exit, node }) => {
              if (onPress) {
                onPress()
              }
            },
            length: duration ? duration : utils.getTransitionExitDelay()
          }}
          entry={{
            state: side ? {
              side: side
            } : null,
            delay: duration ? duration : utils.getTransitionEntryDelay()
          }}>
          <div className="circle-link__circle"></div>
          <div className="circle-link__text">
            {title}
          </div>
        </TransitionLink> : <button type={isSubmit ? "submit" : null} className={`circle-link ${inverse ? 'circle-link--inverse' : ''} font-mabry tracking-wider uppercase text-sm`} onClick={(e) => {
          if (onPress) {
            onPress()
          }
        }}>
            <div className="circle-link__circle"></div>
            <div className="circle-link__text">
              {title}
            </div>
          </button>
      }
    </div>
  )
}
