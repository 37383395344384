import React, { Component, useEffect, useState } from "react";
import { gsap } from 'gsap'

export default function Cursor({ data }) {

  const [isHidden, setIsHidden] = useState(false);
  const [isAnimating, setIsAnimating] = useState(false);


  useEffect(() => {
    window.onmousemove = (event) => {
      const path = event.path || event.composedPath()
      if (path.some(el => el && el.classList && el.classList.contains('bg-dark')) || document.body.classList.contains('nav-open') || event.target.style.color === 'rgb(237, 228, 223)' || path.some(el => el && el.classList && el.classList.contains('circle-link--inverse'))) {
        document.querySelector('body').classList.add('cursor-inverse')
      } else {
        document.querySelector('body').classList.remove('cursor-inverse')
      }
      if (!event.target.classList.contains('circle-link__circle')) {
        setIsHidden(false)
        setIsAnimating(false)
      }

      if (!isHidden && !isAnimating) {
        gsap.to('.custom-cursor', {
          x: event.clientX - 5,
          y: event.clientY - 5,
          ease: "power2.out",
          duration: 0.1
        })
      }
    }


    const circleLinks = document.querySelectorAll('.circle-link__circle')
    circleLinks.forEach(item => {
      item.addEventListener('mouseenter', e => {
        setIsAnimating(true)
        gsap.to('.custom-cursor', {
          x: e.target.getBoundingClientRect().left,
          y: e.target.getBoundingClientRect().top + 10,
          ease: "power2.out",
          duration: 0.4,
          onComplete: () => {
            setIsAnimating(false)
            setIsHidden(true)
          }
        })

      })
      item.addEventListener('mouseleave', e => {
        setIsHidden(false)
        setIsAnimating(false)
      })
    })
  }, [])

  return (
    <div className={`custom-cursor ${isHidden ? 'custom-cursor--hidden' : ''}`}></div>
  )
}
